.App {
  text-align: left;
  background-color:#000;
  max-height: 80vh;
  overflow: hidden;
}

.App-logo {
  width: 120px;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  padding:5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
